import React, { FunctionComponent } from 'react';
import { ToolResultProps } from '../../../types';
import {URL_CHECKER_SUBSCRIPTION} from "../../../graphql/subscriptions";
import FullResultCard from "./full-result";
import BaseToolWrap from '../BaseToolWrap';

const Tool: FunctionComponent<ToolResultProps> = ({ url, sessionToken, fullResult, onResult, simpleResult, ...rest }) => (
  <BaseToolWrap
    query={URL_CHECKER_SUBSCRIPTION}
    FullResult={FullResultCard}
    SimpleResult={FullResultCard}
    url={url}
    sessionToken={sessionToken}
    fullResult={fullResult}
    onResult={onResult}
    simpleResult={simpleResult}
    {...rest}
  />
);

export default Tool;
