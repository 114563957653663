import React, { FunctionComponent } from 'react';
import { ToolResultProps } from '../../../types';
import {BROKEN_LINKS_SUBSCRIPTION} from "../../../graphql/subscriptions";
import FullResultCard from "./full-result";

import BaseToolWrap from '../BaseToolWrap';

const BrokenLinksTool: FunctionComponent<ToolResultProps> = ({ url, sessionToken, fullResult, onResult, handleScanLimitCheck }) => {
  return (
    <BaseToolWrap
      key={url}
      query={BROKEN_LINKS_SUBSCRIPTION}
      FullResult={FullResultCard}
      SimpleResult={FullResultCard}
      url={url}
      sessionToken={sessionToken}
      fullResult={fullResult}
      onResult={onResult}
      handleScanLimitCheck={handleScanLimitCheck}
    />
  );
};

export default BrokenLinksTool;
