import React, {FC, useState} from 'react';
import {navigate} from 'gatsby';

import Section from '../../components/section';
import ToolTabs from '../../components/scan-tools/ToolTabs';
import Layout from '../../../src/components/layout/layout';
import {Translate} from '../../components/translations';
import {PageHero} from '../../components/page-hero';
import {DomainForm} from '../../components/form/domain-form';
import BrokenLinksTool from '../../components/scan-tools/broken-links';
import PageAnalayzerTool from '../../components/scan-tools/page-analyzer';
import ScanCountChecker from '../../components/scan-tools/scan-counter';
import ToolToken from '../../components/scan-tools/ToolToken';
import Button from '../../components/button';

import {TrackToolScan} from '../../helpers/trackingEvents';
import {ScanResultsPageProps} from '../../types';

import brokenLinksImg from '../../img/home-tools/broken-link.png';
import hexomaticCTA from '../../img/hexomatic-banner/hexomatic-cta.jpg';

const BrokenLinksPage: FC<ScanResultsPageProps> = props => {
  const [scanLimitReached, setScanLimitReached] = useState(false);
  const url = props['*'];
  const hasDomain = url && url.length > 0;

  return (
    <>
      <Layout>
        <PageHero
          title={<Translate name={'Broken link scanner tool'} />}
          subtitle={'Check your website for broken pages using our free broken links checker.'}
        >
          <DomainForm value={url} onSubmit={u => navigate('/pricing/')} />
        </PageHero>
        <Section>
          <div className="col-md-3">
            <ToolTabs active="broken-links" url={url} />
          </div>
          <div className="col-md-9 tool-detail">
            <div className="row">
              <div className="col-md-12">
                {hasDomain && url ? (
                  <ScanCountChecker url={url}>
                    <ToolToken url={url}>
                      {sessionToken => {
                        TrackToolScan('SSLChecker', url);
                        return (
                          <div className="row">
                            <div className="col-md-12">
                              <PageAnalayzerTool
                                url={url}
                                fullResult
                                sessionToken={sessionToken}
                                handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                              />
                            </div>
                            <p>&nbsp;</p>
                            <div className="col-md-12">
                              <BrokenLinksTool
                                url={url}
                                sessionToken={sessionToken}
                                fullResult
                                handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                              />
                            </div>
                          </div>
                        );
                      }}
                    </ToolToken>
                  </ScanCountChecker>
                ) : (
                  <>
                    <div className="row">
                      <div className="col-md-4" style={{marginBottom: '20px'}}>
                        <img src={brokenLinksImg} alt="" style={{width: '100%', maxWidth: '300px'}} />
                      </div>
                      <div className="col-md-8">
                        <p>
                          <strong>What is Broken Link and why is it so important?</strong>
                        </p>
                        <p>
                          A Broken Link, also known as a dead or rotten link is a hyperlink in the page that is linked
                          to an empty or non-existent internal or external page or file.{' '}
                        </p>
                        <p>
                          Having broken links leaves a negative impact on your website's usability and ranking with
                          Search Engines like Google, Bing, Yahoo, Baidu, DuckDuckGo, Yandex, etc.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <p>
                          It's important to understand that the only way for search engines to rank websites is by
                          crawling pages, and if there are dead links on your pages, it can stop search engine spiders
                          from crawling your site and cause removals from the index.
                        </p>
                        <p>
                          Our broken link checker tool helps you to scan and detect the dead (broken or rotten) links on
                          your website for you to fix them. This tool scans internal and external links within the
                          provided domain name and checks their status codes.
                        </p>
                      </div>
                    </div>
                    <div className="row" style={{marginTop: '30px'}}>
                      <div className="col-md-6" style={{margin: 'auto'}}>
                        <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                      </div>
                      <div className="col-md-6">
                        <div className="flex-align-center">
                          <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                          <p>
                            Harness the internet as your own data source, build your own scraping bots and leverage
                            ready made automations to delegate time consuming tasks and scale your business.
                          </p>
                          <p>No coding or PhD in programming required.</p>
                          <div>
                            <Button
                              hexomaticCTA
                              className="monitor_btn"
                              onClick={() =>
                                (window.location.href =
                                  'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                              }
                            >
                              Get started in minutes
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Section>
      </Layout>
    </>
  );
};

export default BrokenLinksPage;
