import React, { Component, Fragment } from "react";
import { Link } from "gatsby";
import FileSaver from 'file-saver';
import PieChartMetrics from "../../pie-chart-metrics";
import { ResultCard } from "../../result-card";
import {
  BrokenLinkResultType,
  BrokenLinksQueryResultProps,
  IGroupLinksByStatusCode,
  IStringMap,
} from "../../../types";
import { Translate } from "../../translations";
import { Accordion, AccordionItem } from "../../accordion";
import { ProgressBar } from "../../progressBar";
import Button from "../../button";
import { findHostName, getUserToken } from "../../../helpers";
import { Modal } from "../../modal";

export const CHART_COLORS = {
  broken: "#d35400",
  success: "#1abc9c",
};

class BrokenLinksFull extends Component<BrokenLinksQueryResultProps> {
  state = {
    scanCompleted: false,
    showLoginDialog: false,
  };

  links: BrokenLinkResultType[] = [];

  componentWillReceiveProps(
    nextProps: Readonly<BrokenLinksQueryResultProps>
  ): void {
    if (nextProps.completed) {
      this.setState({ scanCompleted: true });
    } else if (this.props.links) {
      this.links = [...this.links, ...this.props.links];
    }
  }

  handleSaveCSV = () => {
    if (!getUserToken()) {
      this.setState({ showLoginDialog: true });
      return;
    }

    if (this.props.url) {
      const hostname = findHostName(this.props.url);
      const csvContent = `URL,STATUS,INTERNAL,CONTENT TYPE,NOFOLLOW
${this.links.map(link => (
        `"${link.url}","${link.status}","${link.internal ? 'YES' : 'NO'}","${link.contentType}","${link.nofollow ? 'YES' : 'NO'}"`
      )).join('\n')}
`;
      FileSaver.saveAs(new Blob([csvContent], { type: 'text/csv;charset=utf-8' }), `broken-links-${hostname}.csv`);
    }
  };

  render(): React.ReactNode {
    const { url, links: baseLinks, completed, _error } = this.props;
    const { scanCompleted } = this.state;

    const links = completed ? baseLinks : this.links;
    // sectionGroups[0] - Internal links
    // sectionGroups[1] - External links
    // sectionGroups[2] - Content links
    const sectionGroups: IGroupLinksByStatusCode[] = [{}, {}, {}];

    if (links) {
      for (let index = 0; index < links.length; index++) {
        const link = links[index];
        if (!link.contentType) continue;
        let groupIndex = -1;

        if (link.contentType.indexOf("html") > -1) {
          groupIndex = link.internal ? 0 : 1;
        } else {
          groupIndex = 2;
        }

        if (groupIndex >= 0) {
          let gLinks = sectionGroups[groupIndex][`Status - ${link.status}`];
          if (!gLinks) {
            gLinks = [link];
          } else {
            gLinks.push(link);
          }

          sectionGroups[groupIndex][`Status - ${link.status}`] = gLinks;
        }
      }
    }

    return (
      <Fragment>
        {!scanCompleted && !_error && (
          <div className="text-center">
            <div className="text-center">
              <ProgressBar />
            </div>
            <div className="text-center">
              <Translate name="TOOL_FULL_LOADING_TEXT" />
            </div>
          </div>
        )}
        {url && (
          <div className="row">
            <div className="col-md-12 text-right">
              <PieChartMetrics
                chartData={broken_links_chart_data(links)}
                labels={[
                  {
                    text: "TOTAL_SCANNED_LINKS_NUMBER",
                    value: (links ? links.length : 0).toString(),
                  },
                  {
                    text: "TOTAL_SUCCESS_LINKS_NUMBER",
                    value: (links
                      ? links.filter(l => isLinkSuccess(l)).length
                      : 0
                    ).toString(),
                    color: CHART_COLORS.success,
                  },
                  {
                    text: "TOTAL_BROKEN_LINKS_NUMBER",
                    value: (links
                      ? links.filter(l => !isLinkSuccess(l)).length
                      : 0
                    ).toString(),
                    color: CHART_COLORS.broken,
                  },
                ]}
              />
            </div>
            <p>&nbsp;</p>
            <p className='text-right w-100' title={scanCompleted ? 'Download CSV To Manually View All Links' : 'You can download CSV when scan will be completed'}>
              <Button onClick={this.handleSaveCSV} disabled={!this.state.scanCompleted}>
                Download CSV Report
              </Button>
            </p>
            <div className="col-md-12">
              <ResultCard
                title="INTERNAL_SCANNED_LINKS"
                items={[
                  {
                    description: (
                      <Accordion>
                        {Object.keys(sectionGroups[0]).map(statusCode => (
                          <AccordionItem
                            key={statusCode}
                            title={`${statusCode} (${sectionGroups[0][statusCode].length})`}
                          >
                            {sectionGroups[0][statusCode].slice(0, 5).map((link, index) => (
                              <p key={index}>
                                <span
                                  className={`badge badge-${
                                    link.status === 200 ? "success" : "danger"
                                  }`}
                                >
                                  {link.status}
                                </span>
                                &nbsp;
                                <a href={link.url} target="_blank" rel='noopener'>
                                  {link.url}
                                </a>
                              </p>
                            ))}
                            <p className='text-center pt-3 pb-0'>
                              <span className='text-muted'><Translate name='DOWNLOAD_CSV_HINT_BROKEN_LINKS' /></span>
                            </p>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    ),
                  },
                ]}
              />
              <ResultCard
                title="EXTERNAL_SCANNED_LINKS"
                items={[
                  {
                    description: (
                      <Accordion>
                        {Object.keys(sectionGroups[1]).map(statusCode => (
                          <AccordionItem
                            key={statusCode}
                            title={`${statusCode} (${sectionGroups[1][statusCode].length})`}
                          >
                            {sectionGroups[1][statusCode].slice(0, 5).map((link, index) => (
                              <p key={index}>
                                <span
                                  className={`badge badge-${
                                    link.status === 200 ? "success" : "danger"
                                  }`}
                                >
                                  {link.status}
                                </span>
                                &nbsp;
                                <a href={link.url} target="_blank" rel='noopener'>
                                  {link.url}
                                </a>
                              </p>
                            ))}
                            <p className='text-center pt-3 pb-0'>
                              <span className='text-muted'><Translate name='DOWNLOAD_CSV_HINT_BROKEN_LINKS' /></span>
                            </p>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    ),
                  },
                ]}
              />
              <ResultCard
                title="CONTENT_SCANNED_LINKS"
                items={[
                  {
                    description: (
                      <Accordion>
                        {Object.keys(sectionGroups[2]).map(statusCode => (
                          <AccordionItem
                            key={statusCode}
                            title={`${statusCode} (${sectionGroups[2][statusCode].length})`}
                          >
                            {sectionGroups[2][statusCode].slice(0, 5).map((link, index) => (
                              <p key={index}>
                                <span
                                  className={`badge badge-${
                                    link.status === 200 ? "success" : "danger"
                                  }`}
                                >
                                  {link.status}
                                </span>
                                &nbsp;
                                <a href={link.url} target="_blank" rel='noopener'>
                                  {link.url}
                                </a>
                              </p>
                            ))}
                            <p className='text-center pt-3 pb-0'>
                              <span className='text-muted'><Translate name='DOWNLOAD_CSV_HINT_BROKEN_LINKS' /></span>
                            </p>
                          </AccordionItem>
                        ))}
                      </Accordion>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        )}
        <Modal
          isOpen={this.state.showLoginDialog}
          modalToggle={() => {}}
          title='LOGIN_TO_CONTINUE'
          modalFooter={(
            <Link to='/#login' state={{ goBack: true }}>
              <button
                type="button"
                className="btn btn-secondary"
              >
                <Translate name="GO_TO_LOGIN_PAGE"/>
              </button>
            </Link>
          )}
          >
          <Translate name='REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS' />
        </Modal>
      </Fragment>
    );
  }
}

export function isLinkSuccess(link: BrokenLinkResultType) {
  return link.status >= 200 && link.status < 300;
}

export function broken_links_chart_data(
  links?: BrokenLinkResultType[]
): Array<any> {
  if (!links) return [];
  const linkTypes: any = {};
  const linkColors: IStringMap = {};
  links.map(link => {
    const key = `Status - ${link.status}`;
    linkColors[key] = isLinkSuccess(link)
      ? CHART_COLORS.success
      : CHART_COLORS.broken;
    if (!linkTypes[key]) {
      linkTypes[key] = 0;
    }

    linkTypes[key] += 1;
  });

  return Object.keys(linkTypes).map(k => ({
    name: `${k} (${linkTypes[k]})`,
    value: linkTypes[k],
    fill: linkColors[k],
  }));
}

export default BrokenLinksFull;
