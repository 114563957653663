import React, {Fragment} from 'react';
import {Link} from 'gatsby';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faMedium,
  faPinterestSquare,
  faTwitterSquare,
  faVk,
  faGooglePlus,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import {Translate} from '../../translations';
import {ProgressBar} from '../../progressBar';
import {ResultCard} from '../../result-card';
import {SNIconsType, UrlCheckQueryResultProps} from '../../../types';

export const SocialNetworkNames: SNIconsType = {
  facebook: {icon: faFacebookSquare, color: '#3C5A99'},
  linkedin: {icon: faLinkedin, color: '#4875B4'},
  youtube: {icon: faYoutubeSquare, color: '#FF0000'},
  twitter: {icon: faTwitterSquare, color: '#38A1F3'},
  instagram: {icon: faInstagram, color: '#C13584'},
  medium: {icon: faMedium, color: '#00ab6c'},
  pinterest: {icon: faPinterestSquare, color: '#bd081c'},
  vk: {icon: faVk, color: '#45668e'},
  googleplus: {icon: faGooglePlus, color: '#FF0000'},
};

export default ({
  url,
  canonicalUrl,
  isHttps,
  favicon,
  socialNetworks,
  moreButton,
  mobileMeta,
  _error,
}: UrlCheckQueryResultProps) => (
  <Fragment>
    <div className="row">
      {!url && !_error && (
        <div className="col-md-12">
          <div className="text-center">
            <ProgressBar />
          </div>
        </div>
      )}
    </div>
    {url && url.length > 0 && !_error && (
      <div className="row">
        <div className="col-md-12">
          <ResultCard
            title={
              <>
                {moreButton}
                <Translate name="LINK_CHECKER_RESULT_TITLE" />
              </>
            }
            items={[
              {
                icon: isHttps ? 'check' : 'times',
                title: 'URL_CHECKER_HTTPS_RESULT_TITLE',
                description: isHttps ? (
                  <>
                    <Translate name="URL_CHECKER_HTTPS_RESULT_DESCRIPTION_SUCCESS" />
                    <br />
                    <Link to={`/ssl-certificate-checker/${url}`}>Check Certificate</Link>
                  </>
                ) : (
                  'URL_CHECKER_HTTPS_RESULT_DESCRIPTION_FAIL'
                ),
                success: isHttps,
              },
              {
                icon: mobileMeta ? 'check' : 'times',
                title: 'URL_CHECKER_MOBILE_FIRST_META',
                description: mobileMeta
                  ? 'URL_CHECKER_MOBILE_FIRST_META_DESCRIPTION_SUCCESS'
                  : 'URL_CHECKER_MOBILE_FIRST_META_DESCRIPTION_FAIL',
                success: mobileMeta,
              },
              {
                icon: canonicalUrl ? 'check' : 'times',
                title: 'URL_CHECKER_URL_CANONICAL_URL_TITLE',
                description: canonicalUrl
                  ? 'URL_CHECKER_URL_CANONICAL_RESULT_DESCRIPTION_SUCCESS'
                  : 'URL_CHECKER_URL_CANONICAL_RESULT_DESCRIPTION_FAIL',
                success: canonicalUrl,
              },
              {
                icon: favicon && favicon.hasIcon ? 'check' : 'times',
                title: 'URL_CHECKER_FAVICON_RESULT_TITLE',
                description:
                  favicon && favicon.hasIcon ? (
                    <a href={favicon.favicon} target="_blank" rel="noopener">
                      {favicon.favicon}
                    </a>
                  ) : (
                    'URL_CHECKER_FAVICON_RESULT_DESCRIPTION_FAIL'
                  ),
                success: favicon && favicon.hasIcon,
              },
              {
                icon: socialNetworks && socialNetworks.find((sn: any) => sn.url.length > 0) ? 'check' : 'times',
                title: 'URL_CHECKER_SOCIAL_NETWORKS_RESULT_TITLE',
                description:
                  socialNetworks && socialNetworks.find((sn: any) => sn.url.length > 0) ? (
                    <p>
                      {socialNetworks &&
                        socialNetworks.map(
                          (sn: any) =>
                            SocialNetworkNames[sn.name] &&
                            sn.url.length > 0 && (
                              <a href={sn.url} key={sn.name} target="__blank">
                                <FontAwesomeIcon
                                  icon={SocialNetworkNames[sn.name].icon}
                                  color={SocialNetworkNames[sn.name].color}
                                  size="3x"
                                />
                                &nbsp;
                              </a>
                            ),
                        )}
                    </p>
                  ) : (
                    'URL_CHECKER_SOCIAL_NETWORKS_RESULT_DESCRIPTION_FAIL'
                  ),
                success: socialNetworks && !!socialNetworks.find((sn: any) => sn.url.length > 0),
              },
            ]}
          />
        </div>
      </div>
    )}
  </Fragment>
);
